.services-container {
    display: flex;
    flex-direction: column;
    
    .services-body {
    padding: 50px;

    .section-body{
        display: flex;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .section-p{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 50%;
            padding: 32px;

            @media (max-width: 768px) {
                width: 100%;
                .request-button{
                    width: 100% !important;
                }
                .call-button {
                    display: flex !important;
                }
            }

            .request-button{
                width: 50%;
            }
            .call-button {
                display: none;
            }

            .paragraph{
                margin-bottom: 16px;
            }
        }

        .section-image{
            width: 50%;

            @media (max-width: 768px) {
                width: 100% !important;
            }
        }
    }
}
}