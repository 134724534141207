
.experties-container {
    display: flex;
    flex-direction: column;
    
    .experties-body {
    padding: 50px;

    .section-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
}