

.overview-container {
    display: flex;
    flex-direction: column;
    
    .overview-body {
    padding: 50px;

    .section-body{
        display: flex;
        @media (max-width: 768px) {
            flex-direction: column;
            .section-p{
                width: 100% !important;
    
                .paragraph{
                    margin-bottom: 16px;
                }
            }
        }

        .section-p{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            padding-right: 32px;

            .paragraph{
                margin-bottom: 16px;
            }
        }

        .section-image{
            width: 50%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}
}