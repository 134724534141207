.home-container {
  .intro-card {
    text-align: center;
    background-image: url("../../assets/images/sfondo_intro.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    align-items: top;
    justify-content: center;
    height: 500px;

    @media (max-width: 768px) {
      height: 250px;
    }

    @media (min-width: 1800px) {
      height: 1000px;
     }
  }

  .second-card {
    margin-top: 48px;
    padding: 32px;
    display: flex;

    .card-description {
      width: 50%;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .image-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
 
      .card-description {
       justify-content: center;
       width: 100% !important;
       margin-bottom: 16px;
 
       .button-readme{
         width: 100% !important;
       }
      }

      .image-card {
        width: 100%;
      }
     }
  }

  .contacts-card {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    .consents {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 16px;
  }

    @media (max-width: 768px) {
      .contacts-form{
        width: 90% !important;
      }
    }
  }
}
