.contacts-container {
    .contacts-card {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .contacts-form{
            width: 50%;
            align-self: "center";
        
            @media (max-width: 768px) {
                width: 90% !important;
            }
        }

        .consents {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 20px;
            gap: 16px;
        }
    }
}