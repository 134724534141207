.footer-container {
    margin-top: 32px;
    padding: 0 16px;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--mui-palette-background-paper);
    color: var(--mui-palette-text-primary);
    border-top: 1px solid #e0e0e0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

    .footer-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 8px;
    }

    .footer-links {
        display: flex;
        justify-content: center;
        gap: 16px;
        white-space: nowrap;

        a {
            color: inherit;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover {
                color: var(--mui-palette-primary-main);
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 600px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
        padding: 16px 8px;

        .footer-info, .footer-links {
            margin: 8px 0;
        }
    }
}

.footer-container a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
} 

.footer-container .footer-info a {
    color: var(--mui-palette-primary-main);
}

.footer-container .footer-links a {
    font-weight: bold;
} 

.footer-container .footer-info, 
.footer-container .footer-links {
    padding: 8px 0;
} 

.footer-container .footer-info {
    line-height: 1.6;
}

.footer-container .footer-links {
    display: flex;
    justify-content: space-evenly;
} 

.footer-container {
    background-color: #f9f9f9;
    color: #333;
}

.footer-container .footer-info {
    font-size: 14px;
} 

.footer-container .footer-links a {
    font-size: 16px;
}

.footer-container {
    border-top: 1px solid #ddd;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
} 

.footer-container {
    padding: 20px;
}

.footer-container .footer-info, 
.footer-container .footer-links {
    margin: 0 20px;
} 

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
    }

    .footer-container .footer-info, 
    .footer-container .footer-links {
        margin: 10px 0;
    }
}

.footer-container {
    transition: background-color 0.3s ease;
}

.footer-container:hover {
    background-color: #f0f0f0;
} 

.footer-container .footer-links a:hover {
    color: #007BFF;
    text-decoration: underline;
} 

.footer-container .footer-info a:hover {
    color: #0056b3;
} 

.footer-container {
    font-family: 'Arial', sans-serif;
}

.footer-container .footer-info {
    font-family: 'Roboto', sans-serif;
}

.footer-container .footer-links a {
    font-family: 'Open Sans', sans-serif;
} 

.footer-container .footer-info {
    text-align: left;
} 

.footer-container .footer-links {
    text-align: right;
} 

@media (max-width: 600px) {
    .footer-container .footer-info, 
    .footer-container .footer-links {
        text-align: center;
    }
}

.footer-container {
    border-radius: 8px 8px 0 0;
}

.footer-container .footer-info, 
.footer-container .footer-links {
    border-radius: 4px;
} 

.footer-container {
    background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
}

.footer-container .footer-links a {
    background-color: rgba(0, 123, 255, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
} 

.footer-container .footer-links a:hover {
    background-color: rgba(0, 123, 255, 0.2);
}

.footer-container .footer-info {
    color: #555;
}

.footer-container .footer-links a {
    color: #007BFF;
}

.footer-container .footer-links a:hover {
    color: #0056b3;
}

.footer-container {
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer-container {
    padding: 16px 32px;
}

.footer-container .footer-info, 
.footer-container .footer-links {
    margin: 0 16px;
}

.footer-container {
    font-size: 14px;
}

.footer-container .footer-links a {
    font-size: 16px;
    font-weight: 600;
}

.footer-container {
    border-top: 2px solid #ccc;
}

.footer-container {
    transition: all 0.3s ease;
}

.footer-container:hover {
    background-color: #f7f7f7;
}

.footer-container .footer-links a {
    transition: color 0.3s ease;
}

.footer-container .footer-links a:hover {
    color: #004085;
}

.footer-container .footer-info a {
    transition: color 0.3s ease;
}

.footer-container .footer-info a:hover {
    color: #003366;
}

.footer-container {
    font-family: 'Helvetica Neue', sans-serif;
}

.footer-container .footer-info {
    font-family: 'Arial', sans-serif;
}

.footer-container .footer-links a {
    font-family: 'Verdana', sans-serif;
}

.footer-container .footer-info {
    line-height: 1.5;
}

.footer-container .footer-links {
    line-height: 1.8;
}

.footer-container {
    border-radius: 0 0 8px 8px;
}

.footer-container .footer-info, 
.footer-container .footer-links {
    border-radius: 4px;
}

.footer-container {
    background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
}

.footer-container .footer-links a {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 6px 12px;
    border-radius: 4px;
}

.footer-container .footer-links a:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.footer-container {
    color: #222;
}

.footer-container .footer-info {
    color: #444;
}

.footer-container .footer-links a {
    color: #0066cc;
}

.footer-container .footer-links a:hover {
    color: #004499;
}

.footer-container {
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
}

.footer-container {
    padding: 24px;
}

.footer-container .footer-info, 
.footer-container .footer-links {
    margin: 0 24px;
}

.footer-container {
    font-size: 15px;
}

.footer-container .footer-links a {
    font-size: 17px;
    font-weight: bold;
}

.footer-container {
    border-top: 1px solid #bbb;
}

.footer-container {
    transition: background-color 0.4s ease;
}

.footer-container:hover {
    background-color: #eeeeee;
}

.footer-container .footer-links a {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-container .footer-links a:hover {
    background-color: rgba(0, 0, 0, 0.15);
    color: #003366;
}

.footer-container .footer-info a {
    transition: color 0.3s ease;
}

.footer-container .footer-info a:hover {
    color: #002244;
}
