.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px 0 50px;
    height: 120px;

    .header-links {
      display: flex;
      align-items: center;
  
      .drawer-links {
        width: 200px; /* Adjust drawer width based on your design */
        padding: 20px;
      }
  
      .desktop-links {
        display: flex;
        align-items: center;
  
        .link-mui {
          padding: 24px;
          color:black;
        }

        .active-link{
          padding: 24px;
          color:black;
          text-decoration: underline !important;
        }
      }
  
      .book-button-div {
        margin-left: auto;
        padding: 24px;
      }
  
    @media (max-width: 768px) {
      .desktop-links {
        display: none; /* Hide desktop links on small screens */
      }
      .drawer-filter-button{
        display: flex !important;
      }
    }
}
}
  

